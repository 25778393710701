import {
  apiDeleteApprovalChain,
  apiDeleteMultipleApprovalChains,
  apiFetchApprovalChains,
  apiFetchChainMatchers,
  apiFetchLinkApprovers,
  apiFetchLinks,
} from '@/offers/api/approval-chains';

const state = {
  chainMatcher: {},
  chainMatchers: [],
  links: [],
  linkApprovers: [],
  approvalChains: [],
  showCreatePage: false,
  approvalChain: {},
  link: {},
  editExistingChain: false,
  copyExistingChain: false,
  approvalChainsSet: false,
  isLoading: false,
};

const getters = {
  getChainRanges: (state) => state.chainRanges,
  getChainMatcher: (state) => state.chainMatcher,
  getChainMatchers: (state) => state.chainMatchers,
  getLinks: (state) => state.links,
  getLinkApprovers: (state) => state.linkApprovers,
  getApprovalChains: (state) => state.approvalChains,
  getShowCreatePage: (state) => state.showCreatePage,
  getApprovalChain: (state) => state.approvalChain,
  getLink: (state) => state.link,
  getEditExistingChain: (state) => state.editExistingChain,
  getCopyExistingChain: (state) => state.copyExistingChain,
  getApprovalChainsSet: (state) => state.approvalChainsSet,
  isLoading: (state) => state.isLoading,
};

const mutations = {
  setLinks(state, links) {
    state.links = links;
  },
  setLinkApprovers(state, approvers) {
    state.linkApprovers = approvers;
  },
  setApprovalChains(state, chains) {
    state.approvalChains = chains;
    state.approvalChainsSet = true;
  },
  setChainMatcher(state, matcher) {
    state.chainMatcher = matcher;
  },
  setChainMatchers(state, matchers) {
    state.chainMatchers = matchers;
  },
  setShowCreatePage(state, boolean) {
    state.showCreatePage = boolean;
  },
  setApprovalChain(state, chain) {
    state.approvalChain = chain;
  },
  setLink(state, link) {
    state.link = link;
  },
  removeApprovalChain(state, id) {
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line eqeqeq
    state.approvalChains = state.approvalChains.filter((chain) => chain.id != id);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line eqeqeq
    state.links = state.links.filter((link) => link.chain != id);
    // TODO: Fix this the next time the file is edited.
    // eslint-disable-next-line eqeqeq
    state.linkApprovers = state.linkApprovers.filter((approver) => approver.chain != id);
  },
  removeMultipleApprovalChains(state, ids) {
    state.approvalChains = state.approvalChains.filter((chain) => !ids.includes(chain.id));
    state.links = state.links.filter((link) => !ids.includes(link.chain));
    state.linkApprovers = state.linkApprovers.filter((approver) => !ids.includes(approver.chain));
  },
  setEditExistingChain(state, boolean) {
    state.editExistingChain = boolean;
  },
  addChain(state, chain) {
    state.approvalChains.push(chain);
  },
  addLink(state, link) {
    state.links.push(link);
  },
  addApprover(state, approver) {
    state.linkApprovers.push(approver);
  },
  setCopyExistingChain(state, boolean) {
    state.copyExistingChain = boolean;
  },
  setIsLoading(state, loading) {
    state.isLoading = loading;
  },
};

const actions = {
  setShowCreatePage(context, boolean) {
    context.commit('setShowCreatePage', boolean);
  },
  async fetchLinks({ commit }) {
    const { data } = await apiFetchLinks();
    commit('setLinks', data);
  },
  async fetchLinkApprovers({ commit }) {
    const { data } = await apiFetchLinkApprovers();
    commit('setLinkApprovers', data);
  },
  async fetchApprovalChains({ commit }) {
    const { data } = await apiFetchApprovalChains();
    commit('setApprovalChains', data);
  },
  async fetchChainMatcher(context, data) {
    try {
      const params = { params: data };
      const response = await apiFetchChainMatchers(params);
      const matcher = response.data.length > 0 ? response.data[0] : {};

      context.commit('setChainMatcher', matcher);
    } catch (error) {
      console.log(error);
    }
  },
  async fetchChainMatchers(context, data) {
    try {
      const params = { params: data };
      const response = await apiFetchChainMatchers(params);
      context.commit('setChainMatchers', response.data);
    } catch (error) {
      console.log(error);
    }
  },
  async setApprovalChain({ commit }, chain) {
    try {
      await commit('setApprovalChain', chain);
    } catch (error) {
      console.error(error);
    }
  },
  deleteApprovalChain(context, id) {
    context.commit('removeApprovalChain', id);

    return apiDeleteApprovalChain(id).then((response) => {});
  },
  async deleteMultipleApprovalChains(context, ids) {
    await apiDeleteMultipleApprovalChains(ids);
    context.commit('removeMultipleApprovalChains', ids);
  },
  setEditExistingChain(context, boolean) {
    context.commit('setEditExistingChain', boolean);
  },
  setCopyExistingChain(context, boolean) {
    context.commit('setCopyExistingChain', boolean);
  },
  replaceApprovalChain({ commit }, { oldChainId, newChain }) {
    commit('removeApprovalChain', oldChainId);
    commit('addChain', newChain);
  },
  setIsLoading(context, value) {
    context.commit('setIsLoading', value);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
