var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.mount)?_c('Portal',{attrs:{"selector":_vm.appendTo}},[(_vm.outsideClickClose)?_c('div',{staticClass:"fixed bottom-0 top-0 h-screen w-full",style:({ 'z-index': _vm.zIndex - 1 }),attrs:{"tabindex":"-1"},on:{"click":_vm.close}}):_vm._e(),_c('Transition',{attrs:{"name":"drawer-transition","enter-active-class":_vm.transitionInClass,"leave-active-class":_vm.transitionOutClass},on:{"before-enter":_vm.beforeOpen,"enter":_vm.opening,"after-enter":_vm.opened,"before-leave":_vm.beforeClose,"leave":_vm.closing,"after-leave":_vm.closed}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],class:[_vm.drawerClass, _vm.position === 'right' ? 'drawer-right' : 'drawer-left'],style:({ 'z-index': _vm.zIndex, ..._vm.drawerStyle }),attrs:{"data-drawer-id":_vm.id,"tabindex":"-1","role":"dialog","aria-label":_vm.title,"aria-modal":"false","aria-describedby":`${_vm.id}-content`,"aria-labelledby":`${_vm.id}-title`}},[_vm._t("title-wrapper",function(){return [_c('div',{class:[
            'flex items-center border-b border-p-gray-30 px-6 pb-4 pt-4',
            { 'flex-row-reverse': _vm.position === 'right' },
          ]},[_c('PCloseBtn',{staticClass:"flex-0",attrs:{"aria-label":_vm.closeLabel},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}}),_vm._t("title",function(){return [(_vm.title)?_c('h3',{staticClass:"text-display-small flex-1",class:{ 'text-right': _vm.position === 'left' },attrs:{"id":`${_vm.id}-title`}},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e()]})],2)]}),(_vm.errorMsg)?_c('div',{staticClass:"mt-6 px-6"},[_c('PAlert',{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.errorMsg))])],1):_vm._e(),_vm._t("content-wrapper",function(){return [_c('div',{class:[
            'relative mt-6 grow overflow-y-auto overflow-x-hidden px-6',
            { 'pointer-events-none opacity-50': _vm.disabled },
          ]},[_vm._t("default")],2)]}),_vm._t("footer-wrapper",function(){return [_c('div',{staticClass:"footer mt-6 px-6 pb-6"},[_vm._t("footer")],2)]})],2)])],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }