import { ACCESS_COOKIE_NAME, ACCESS_TOKEN, REFRESH_TOKEN } from '@/utils/constants.js';
import { PEQUITY_API_URL, PEQUITY_BASE_URL } from '@/environment.js';

export const DEBUG = process?.env?.NODE_ENV === 'development';

const getHost = () => window.location.host.split(':')[0];

// XXX This should be in the .env file.
export function getUrlBase() {
  const protocol = window.location.protocol + '//';
  const split = window.location.host.split(':');
  const newHost = split.length > 1 ? split[0] + ':8000' : split[0];
  return protocol + newHost;
}

export const isValidEmail = (email) => /\S+@\S+\.\S+/.test(email);

export const URL_BASE = getUrlBase();
export const HOST = getHost();
const SUB_OR_DOMAIN = getHost().split('.')[0];
export const SUBDOMAIN = SUB_OR_DOMAIN === PEQUITY_BASE_URL ? null : SUB_OR_DOMAIN;

/*
 * RegExp breakdown:
 * ([^\\.]*) the capture group to get our tenant (matches any non `.` character)
 * \\.{0,1} matches zero or one `.` characters (should be the `.` between subdomain and base url)
 * ${PEQUITY_BASE_URL} string interpolation to match the .env base url
 */
const subAndHostRegExp = new RegExp(`([^\\.]*)\\.{0,1}${PEQUITY_BASE_URL}`);
const matches = subAndHostRegExp.exec(HOST);
export const TENANT = matches?.[1] || null;

const getApiUrl = () => {
  const url = new URL(PEQUITY_API_URL);
  url.host = SUBDOMAIN ? `${SUBDOMAIN}.${url.host}` : url.host;

  return url.href;
};
export const API_URL = getApiUrl();

/**
 * WARNING
 * This function does not do what the name says. It does not convert to int. It converts to int or float.
 * So, if that's what you need, make sure to convert it's result to an actual integer.
 * I'd rename it but it's used 304 times at the moment of me writing this comment, and ain't nobody got time for that.
 */
export function convertStringToInt(input) {
  return typeof input === 'string' ? Number(input.replace(/[^0-9.]+/g, '')) : input;
}

export function isDev() {
  return !!window.webpackHotUpdate;
}

export function capitalize(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const tokenExpired = (token) => {
  if (!token) {
    return true;
  }
  const msExpiration = JSON.parse(atob(token.split('.')[1])).exp;
  const adjustedExpiration = msExpiration * 1000 - 15000;
  return adjustedExpiration < Date.now();
};

const setAccessCookie = (cookie) => {
  document.cookie = `${ACCESS_COOKIE_NAME}=${cookie}; path=/;`;
};

export const clearTokens = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  setAccessCookie('');
  localStorage.removeItem(REFRESH_TOKEN);
};

export const createTenantObject = (obj) =>
  Object.entries(obj).reduce((acc, [key, values]) => {
    acc[key] = values[SUBDOMAIN] || values.DEFAULT;
    return acc;
  }, {});

const randomString = () => Math.random().toString(36).slice(2);
const randomStringFromDate = () => Date.now().toString(36);
export const makeUuid = () => `${randomString()}${randomStringFromDate()}`;
export const makeIterableWithUuid = (arr) => {
  return arr.map((elem) => {
    return [elem, makeUuid()];
  });
};

const plural = (count, one, multiple) => (Math.abs(count) === 1 ? one : multiple);

export const displayCount = (count, one, multiple) => `${count || 0} ${plural(count, one, multiple)}`;

export const absoluteLink = (relative) => `${window.location.host}${relative}`;

export const setAccessToken = (token) => {
  localStorage.setItem(ACCESS_TOKEN, token);
  setAccessCookie(`Bearer ${token}`);
};

export const setRefreshToken = (token) => {
  localStorage.setItem(REFRESH_TOKEN, token);
};

export const generateGuid = () => {
  const s = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  return `${s()}${s()}-${s()}-${s()}-${s()}-${s()}${s()}${s()}`;
};
