import Vue from 'vue';
import VueRouter from 'vue-router';
import { IS_CCPV2_ROUTE, IS_PUBLIC, NO_AUTH_NEEDED } from '@/utils/symbols.js';
import { handleChunkLoadError, isChunkLoadError } from '@/router/handleChunkLoadError';
import { redirectToV2 } from '@/utils/redirect';

// Module Routes
import { isDev } from '@/helpers';
import { offersRoutes } from '@/offers/router/routes';
import { playgroundRoutes } from '@/playground/router/routes';
import { settingsRoutes } from '@/settings/router/routes';

// Guards
import { handleHelpWidgetClosing } from '@/help/router/guards';
import {
  handleLoggedOutUsers,
  handleNonPublicRoutes,
  handlePreviousTenantSpecificLoginRoute,
  handleUniversalLoginRedirection,
  handleV2RoutesInDevMode,
  loggedInActions,
  multiGuard,
} from '@/router/guards.js';

const PagesError404 = () => import('@/components/pages/error-page-404.vue');
const PagesLogin = () => import('@pages/login');

const routes = [
  {
    path: '/login',
    name: 'universal-login',
    meta: {
      [NO_AUTH_NEEDED]: true,
      [IS_PUBLIC]: true,
    },
    // TODO: Clean up when migration is complete
    beforeEnter(to) {
      redirectToV2(to);
    },
  },
  {
    path: '/welcome-back',
    name: 'welcome-back',
    component: PagesLogin,
    meta: {
      [NO_AUTH_NEEDED]: true,
    },
    beforeEnter(to, from, next) {
      if (!isDev) {
        redirectToV2(to);
      } else {
        next();
      }
    },
  },
  {
    path: '/find-your-url',
    name: 'find-your-url',
    meta: {
      [NO_AUTH_NEEDED]: true,
      [IS_PUBLIC]: true,
    },
    // TODO: Clean up when migration is complete
    beforeEnter(to) {
      redirectToV2(to);
    },
  },
  {
    path: '/loginadmin',
    name: 'login-admin',
    meta: {
      [NO_AUTH_NEEDED]: true,
    },
    // TODO: Clean up when migration is complete
    beforeEnter(to) {
      redirectToV2(to);
    },
  },
  {
    path: '/forgot-password',
    children: [
      {
        path: '',
        name: 'forgot-password',
        meta: {
          [NO_AUTH_NEEDED]: true,
        },
        // TODO: Clean up when migration is complete
        beforeEnter(to) {
          redirectToV2(to);
        },
      },
      {
        path: '/email-sent',
        name: 'email-sent',
        meta: {
          [NO_AUTH_NEEDED]: true,
        },
        // TODO: Clean up when migration is complete
        beforeEnter(to) {
          redirectToV2(to);
        },
      },
      {
        path: '/password-updated',
        name: 'password-updated',
        meta: {
          [NO_AUTH_NEEDED]: true,
        },
        // TODO: Clean up when migration is complete
        beforeEnter(to) {
          redirectToV2(to);
        },
      },
      {
        path: '/token-expired',
        name: 'token-expired',
        meta: {
          [NO_AUTH_NEEDED]: true,
        },
        // TODO: Clean up when migration is complete
        beforeEnter(to) {
          redirectToV2(to);
        },
      },
    ],
  },
  {
    path: '/forgot-password/:token',
    name: 'validate-token',
    meta: {
      [NO_AUTH_NEEDED]: true,
    },
    // TODO: Clean up when migration is complete
    beforeEnter(to) {
      redirectToV2(to);
    },
  },
  {
    path: '/mfa-token',
    name: 'mfa-token',
    meta: {
      [NO_AUTH_NEEDED]: true,
    },
    // TODO: Clean up when migration is complete
    beforeEnter(to) {
      redirectToV2(to);
    },
  },
  {
    path: '/invite/:id',
    name: 'invite',
    meta: {
      [NO_AUTH_NEEDED]: true,
    },
    // TODO: Clean up when migration is complete
    beforeEnter(to) {
      redirectToV2(to);
    },
  },
  {
    path: '/saml/login',
    name: 'saml-login',
    meta: {
      [NO_AUTH_NEEDED]: true,
    },
    // TODO: Clean up when migration is complete
    beforeEnter(to) {
      redirectToV2(to);
    },
  },
  {
    path: '/ccpv2*',
    name: 'ccpv2',
    meta: {
      [IS_CCPV2_ROUTE]: true,
      [NO_AUTH_NEEDED]: true,
    },
    // TODO: Clean up when migration is complete
    beforeEnter(to) {
      redirectToV2(to);
    },
  },
  {
    path: '/visual-offers*',
    name: 'visual-offers',
    meta: {
      [NO_AUTH_NEEDED]: true,
    },
    // TODO: Clean up when migration is complete
    beforeEnter(to) {
      redirectToV2(to);
    },
  },
  {
    path: '/ranges',
    name: 'ranges',
    meta: {
      [NO_AUTH_NEEDED]: true,
    },
    // TODO: Clean up when migration is complete
    beforeEnter(to) {
      redirectToV2(to);
    },
  },
  {
    path: '',
    name: 'home',
    meta: {
      [NO_AUTH_NEEDED]: true,
    },
    // TODO: Clean up when migration is complete
    beforeEnter(to) {
      redirectToV2(to);
    },
  },
  {
    path: '/people*',
    name: 'people',
    meta: {
      [NO_AUTH_NEEDED]: true,
    },
    // TODO: Clean up when migration is complete
    beforeEnter(to) {
      redirectToV2(to);
    },
  },
  {
    path: '/employee-portal',
    name: 'employee-portal',
    meta: {
      [NO_AUTH_NEEDED]: true,
    },
    // TODO: Clean up when migration is complete
    beforeEnter(to) {
      redirectToV2(to);
    },
  },
  {
    path: '/pbe*',
    name: 'pbe',
    meta: {
      [NO_AUTH_NEEDED]: true,
    },
    // TODO: Clean up when migration is complete
    beforeEnter(to) {
      redirectToV2(to);
    },
  },
  ...offersRoutes,
  ...playgroundRoutes,
  ...settingsRoutes,
  {
    // Wild-card routes should be last:
    // https://router.vuejs.org/guide/essentials/dynamic-matching.html#catch-all-404-not-found-route
    path: '*',
    name: 'not-found',
    component: PagesError404,
  },
];

Vue.use(VueRouter);

const router = new VueRouter({
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
  mode: 'history',
  routes,
});

router.beforeEach(
  multiGuard(
    handleV2RoutesInDevMode,
    handleUniversalLoginRedirection,
    handleNonPublicRoutes,
    handlePreviousTenantSpecificLoginRoute,
    handleHelpWidgetClosing,
    handleLoggedOutUsers,
    loggedInActions
  )
);

/* Handle ChunkLoadError */
handleChunkLoadError(router);

/* Mute NavigationDuplicated and ChunkLoad errors and only these */
const originalPush = router.push;
router.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject);
  }

  return originalPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err) || isChunkLoadError(err)) {
      return err;
    }

    return Promise.reject(err);
  });
};

export { router };
