const LABEL_BASE = `block mb-1 font-bold`;

const LABEL_SIZES = {
  sm: `text-xs`,
  md: `text-sm`,
  lg: `text-base`,
};

const LABEL_REQUIRED = `after:content-['_*'] after:text-on-error`;

const ERROR_MSG = `text-xs text-on-error mt-1`;

const INPUT_BASE = [
  'text-night',
  'w-full',
  'bg-surface',
  'border',
  'hover:border-transparent',
  'hover:ring-1',
  'hover:ring-primary',
  'focus:outline-none',
  'focus:ring-2',
  'focus:ring-primary',
  'focus:border-transparent',
  'disabled:bg-p-blue-10',
  'disabled:cursor-default',
].join(' ');

const INPUT_SIZES = {
  sm: `h-8 text-sm rounded`,
  md: `h-10 text-base rounded`,
  lg: `h-12 text-lg rounded`,
};

const TEXTAREA_BASE = `resize-y px-3 py-3 overflow-auto h-auto`;

const SELECT_BASE = 'truncate';

const SELECT_ARROW = `bg-[url('~@squirrel/assets/chevron-down.svg')] bg-no-repeat appearance-none`;

const SELECT_SIZES = {
  sm: `bg-[right_0.675rem_center] pr-8`,
  md: `bg-[right_1rem_center] pr-10`,
  lg: `bg-[right_1.25rem_center] pr-12`,
};

const SPACING_LEFT = {
  sm: `pl-3`,
  md: `pl-4`,
  lg: `pl-5`,
};

const SPACING_RIGHT = {
  sm: `pr-3`,
  md: `pr-4`,
  lg: `pr-5`,
};

const SPACING_PREFIX = {
  sm: `pl-6`,
  md: `pl-8`,
  lg: `pl-12`,
};

const SPACING_SUFFIX = {
  sm: `pr-6`,
  md: `pr-8`,
  lg: `pr-12`,
};

export default {
  props: {
    size: {
      type: String,
      default: 'md',
      validator(value) {
        return Object.keys(INPUT_SIZES).includes(value);
      },
    },
  },
  data() {
    return {
      errorMsgClasses: ERROR_MSG,
    };
  },
  computed: {
    inputClasses() {
      const base = `${INPUT_BASE} ${INPUT_SIZES[this.size]}`;
      const spacingLeft = this.$slots.prefix ? SPACING_PREFIX[this.size] : SPACING_LEFT[this.size];
      const spacingRight = this.$slots.suffix ? SPACING_SUFFIX[this.size] : SPACING_RIGHT[this.size];
      let res = `${base} ${spacingLeft} ${spacingRight}`;
      res = this.errorMsg ? `${res} border-on-error` : `${res} border-p-gray-30`;

      return res;
    },
    labelClasses() {
      const base = `${LABEL_BASE} ${LABEL_SIZES[this.size]}`;
      const res = this.required ? `${base} ${LABEL_REQUIRED}` : base;

      return res;
    },
    selectClasses() {
      const res = `${this.inputClasses.replace(`${SPACING_RIGHT[this.size]} `, '')} ${SELECT_BASE} ${SELECT_ARROW} ${
        SELECT_SIZES[this.size]
      }`;

      return res;
    },
    textareaClasses() {
      const res = `${this.inputClasses} ${TEXTAREA_BASE}`;

      return res;
    },
  },
};
