import * as Sentry from '@sentry/vue';
import axios from 'axios';
import tokenRequest from '@/axios/instance/token.js';
import { REFRESH_ROUTE, REFRESH_TOKEN } from '@/utils/constants.js';
import { TENANT, clearTokens, setAccessToken, setRefreshToken } from '@/helpers.js';
import { api } from '@/axios/instance/api';
import { getField, updateField } from 'vuex-map-fields';
import { router } from '@/router';

// Signup requests
const fetchTenantByUser = (tenant) => api.get('signup/user-tenant', { params: { name: tenant } });
const fetchUserTenantLogin = (email) => api.get('signup/find-tenant-login', { params: { email } });

const protocol = window.location.protocol + '//';
const split = window.location.host.split(':');
const newHost = split.length > 1 ? split[0] + ':8000' : split[0];
const urlBase = protocol + newHost;

const state = {
  loggedIn: false,
  profile: {},
  validation: { email: true },
  authError: false,
  contextMenuOpen: false,
  path: '',
  currentPage: 'freePageOffers',
  loading: false,
  corelationId: null,
};

const getters = {
  getField,
  getProfile: (state) => state.profile,
  getLoggedIn: (state) => state.loggedIn,
  getContextMenuOpen: (state) => state.contextMenuOpen,
  loginRedirectPath: (state) => (currentRoute) => state.path || currentRoute.query?.redirect || '/',
  getLoading: (state) => state.loading,
  getCurrentPage: (state) => state.currentPage,
  corelationId: (state) => state.corelationId,
  isUserAdmin: (state) => !!state.profile.is_admin,
};

function profile2heap(profile) {
  profile = JSON.parse(JSON.stringify(profile));
  Object.keys(profile).forEach((k) => {
    const value = profile[k];
    profile[k] = typeof value === 'string' ? value : JSON.stringify(value);
  });
  profile.tenant = TENANT;
  delete profile.avatar;
  return profile;
}

const mutations = {
  updateField,
  login(state) {
    state.loggedIn = true;
  },
  logout(state) {
    state.loggedIn = false;
  },
  setProfile(state, payload) {
    const { company_schema_name, id, email, full_name } = payload;
    state.profile = payload;
    if (process.env.NODE_ENV === 'production') {
      Sentry.setUser({ email });
      window.heap.identify(email);
      window.heap.addUserProperties(profile2heap(payload));
      if (typeof window.Appcues !== 'undefined') {
        window.Appcues.identify(id, {
          email,
          company_schema_name,
          full_name,
        });
      }
    }
  },
  setValidationEmail(state, bool) {
    state.validation.email = bool;
  },
  setAuthError(state, bool) {
    state.authError = bool;
  },
  setContextMenuOpen(state, bool) {
    state.contextMenuOpen = bool;
  },
  setPath(state, path) {
    state.path = path;
  },
  loading(state, bool) {
    state.loading = bool;
  },
  setCurrentPage(state, page) {
    state.currentPage = page;
  },
  corelationId(state, corelationIdValue) {
    state.corelationId = corelationIdValue;
  },
  setUserTenants(state, payload) {
    state.userTenants = payload;
  },
};

const actions = {
  async refreshToken() {
    const refreshBody = { refresh: localStorage.getItem(REFRESH_TOKEN) };
    try {
      const { data } = await tokenRequest.post(REFRESH_ROUTE, refreshBody);
      setAccessToken(data.access);
      if (data.refresh) {
        setRefreshToken(data.refresh);
      }
      return Promise.resolve(data.access);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async postLogin(context, payload) {
    try {
      const authData = await axios.post(urlBase + '/api/token/both/', payload);
      setAccessToken(authData.data.access);
      setRefreshToken(authData.data.refresh);

      const userProfile = await axios.get(urlBase + '/api/users/profile/');
      context.commit('login', true);
      context.commit('setProfile', userProfile.data);
    } catch (e) {
      context.commit('setAuthError', true);
      throw e;
    }
    context.commit('loading', false);
  },
  credentialsLoginStep(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      axios
        .post(urlBase + '/api/users/credentials_verification/', payload)
        .then((response) => {
          context.commit('loading', false);
          context.commit('corelationId', response.headers['x-correlation-id']);
          resolve(response);
        })
        .catch((error) => {
          context.commit('setAuthError', true);
          context.commit('loading', false);
          reject(error.response);
        });
    });
  },
  resendCode(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      const headers = {
        'X-Correlation-ID': context.state.corelationId,
      };

      axios
        .post(urlBase + '/api/users/resend_mfa_token/', payload, {
          headers: {
            ...headers,
          },
        })
        .then((response) => {
          context.commit('setAuthError', false);
          context.commit('loading', false);
          context.commit('corelationId', response.headers['x-correlation-id']);
          resolve(response);
        })
        .catch((error) => {
          context.commit('setAuthError', true);
          context.commit('loading', false);
          reject(error.response);
        });
    });
  },
  mfaTokenVerificationStep(context, payload) {
    return new Promise((resolve, reject) => {
      context.commit('loading', true);
      const headers = {
        'X-Correlation-ID': context.state.corelationId,
      };

      axios
        .post(urlBase + '/api/users/mfa_token_verification/', payload, {
          headers: {
            ...headers,
          },
        })
        .then((response) => {
          if (response.status === 200) {
            context.commit('login');
            context.commit('setProfile', response.data);
            context.commit('loading', false);
            context.commit('corelationId', null);
            // we set Adems token here
            // localStorage.setItem('token', response.data.token);
            setAccessToken(response.data.access_token);
            setRefreshToken(response.data.refresh_token);
          }
          resolve(response);
        })
        .catch((error) => {
          context.commit('setAuthError', true);
          context.commit('loading', false);
          reject(error.response);
        });
    });
  },
  manualLogin({ commit }, payload) {
    commit('login');
    commit('setProfile', payload);
    commit('loading', false);
    setAccessToken(payload.access_token);
    setRefreshToken(payload.refresh_token);
  },
  getProfile(context) {
    context.commit('loading', true);
    return axios
      .get(urlBase + '/api/users/profile')
      .then((response) => {
        context.commit('login');
        context.commit('setProfile', response.data);
        context.commit('loading', false);
      })
      .catch((e) => {
        context.commit('logout');
        context.commit('loading', false);
      });
  },
  logout(context) {
    context.commit('logout');
    clearTokens();
    return axios.post(urlBase + '/api/users/logout_request/');
  },
  setContextMenuOpen(context, boolean) {
    context.commit('setContextMenuOpen', boolean);
  },
  setLogout(context) {
    context.commit('logout');
  },
  setProfile(context, payload) {
    context.commit('setProfile', payload);
  },
  setCurrentPage(context, page) {
    context.commit('setCurrentPage', page);
  },
  updateProfile(context, payload) {
    return axios.patch(urlBase + '/api/users/' + payload.id, payload).then((response) => {
      context.commit('setProfile', response.data);
    });
  },
  async updateProfileImage(context, payload) {
    const { id, image } = payload;
    const formData = new FormData();
    formData.append('avatar', image);

    try {
      await axios.patch(`${urlBase}/api/users/${id}/update-avatar`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      await context.dispatch('getProfile');
    } catch (error) {
      console.error(error);
    }
  },
  async deleteProfileImage(context, payload) {
    const { id } = payload;
    try {
      await axios.delete(`${urlBase}/api/users/${id}/delete-avatar`);
      await context.dispatch('getProfile');
    } catch (error) {
      console.error(error);
    }
  },
  async fetchUserTenant(context, payload) {
    return await fetchTenantByUser(payload);
  },
  async fetchUserTenantLogin(context, payload) {
    return fetchUserTenantLogin(payload);
  },
  async loginInUserSilently(context, payload) {
    try {
      const authData = await axios.get(`${urlBase}/api/users/silent-login?token=${payload}`);
      setAccessToken(authData.data.access_token);
      setRefreshToken(authData.data.refresh_token);
      const userProfile = await axios.get(urlBase + '/api/users/profile/');
      context.commit('login', true);
      context.commit('setProfile', userProfile.data);
      router.push({ name: 'home' });
    } catch (e) {
      router.push({ name: 'welcome-back' });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
